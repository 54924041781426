@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/light';
@use '@sats-group/ui-lib/tokens/corner-radius';
@use '../../shared-ui/navigation-helpers';
@use '../../shared-ui/breakpoints';

$breakpointLarge: 1920px;
$breakpoint: 1025px;
$breakpointMedium: 800px;
$breakpointSmall: 400px;

$hoursColumnWidth: 25px;

$mainSessionWidth: 190px; // NOTE: Width of session cell
$mediumSessionWidth: 135px; // NOTE: Width of session cell
$minSessionWidth: 110px; // NOTE: Width of session cell

$sessionHeight: 50px;

@mixin siteBreak {
  @media (min-width: $breakpoint) {
    @content;
  }
}

@mixin siteBreakMedium {
  @media (min-width: $breakpointMedium) {
    @content;
  }
}

.calendar {
  display: flex;
  flex-direction: column;
  max-height: calc(100dvh - navigation-helpers.$navMaxHeight);

  @include breakpoints.break {
    max-height: calc(100dvh - navigation-helpers.$navMinHeight);
  }

  &__add-new {
    display: none;
    align-items: center;
    position: relative;

    @include siteBreakMedium {
      display: flex;
    }
  }

  &__add-new-mobile {
    display: flex;
    position: fixed;
    bottom: spacing.$l;
    right: spacing.$l;
    z-index: 98;

    @include siteBreakMedium {
      display: none;
    }
  }

  &__navigation {
    background-color: light.$surface-primary-default;
    border-top: 1px solid light.$ge-divider-default;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap-reverse;
    gap: spacing.$s;
    padding: spacing.$s spacing.$m;

    max-width: $breakpointLarge;
    width: 100%;
    margin: 0 auto;

    @include siteBreak {
      padding: spacing.$s spacing.$xxl;
    }
  }

  &__buttons-left {
    display: flex;
    align-items: center;
    gap: spacing.$m;

    @include siteBreak {
      gap: spacing.$l;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    overflow-x: scroll;
    scroll-padding-left: 25px;
    border-top: 1px solid light.$ge-divider-default;
    max-width: $breakpointLarge;

    @media (min-width: $breakpointLarge) {
      margin: 0 auto;
      border: 1px solid light.$ge-divider-default;
    }
  }

  &__columns-bottom {
    display: flex;
    width: max-content;
  }

  &__column-wrapper {
    display: flex;
    position: relative;
    width: 100%;
  }

  &__hours-wrapper {
    position: sticky;
    left: 0;
    background-color: light.$surface-secondary-default;
    box-shadow: inset -1px 0 0 0 light.$ge-divider-default;
    z-index: 10;
  }

  &__hour {
    text-align: center;
    padding: 0 spacing.$xxs;
    padding-top: spacing.$xxs;
    height: $sessionHeight;
    color: light.$on-surface-primary-alternate;
    border-bottom: 1px solid light.$ge-divider-default;
    font-size: 14px;
  }

  &__dates {
    display: flex;
    position: sticky;
    top: 0;
    z-index: 11;
    width: max-content;
    border-bottom: 1px solid light.$ge-divider-default;
  }

  &__date-header-blank {
    width: $hoursColumnWidth;
    background-color: light.$surface-secondary-default;
    position: sticky;
    left: 0;
    top: 0;
  }

  &__date-header {
    background-color: light.$surface-secondary-default;
    padding: spacing.$xxs 0;
    display: flex;
    gap: spacing.$xs;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    border-right: 1px solid light.$surface-secondary-default;
    width: $mainSessionWidth;

    @media (max-width: $breakpoint) {
      width: $mediumSessionWidth;
    }

    @media (max-width: $breakpointSmall) {
      width: $minSessionWidth;
    }
  }

  &__date-header-text {
    &--highlight {
      border-radius: corner-radius.$m;
      padding: 0 spacing.$xxs;
      background-color: light.$ge-badge-primary;
      color: light.$on-fixed-background-primary-default;
    }
  }

  &__session-column {
    display: flex;
    flex-direction: column;
    position: relative;
    flex-shrink: 0;
    width: $mainSessionWidth;

    @media (max-width: $breakpoint) {
      width: $mediumSessionWidth;
    }

    @media (max-width: $breakpointSmall) {
      width: $minSessionWidth;
    }
  }

  &__session-column-current-hour {
    display: flex;
    position: absolute;
    z-index: 9;
    width: 100%;
  }

  &__session-column-current-hour-ellipse {
    width: 9px;
    height: 9px;
    border-radius: 100%;
    background-color: light.$buttons-cta-default;
    margin-left: -2px;
    margin-top: -4.5px;
  }

  &__session-column-current-hour-line {
    height: 3px;
    width: 100%;
    background-color: light.$buttons-cta-default;
    margin-left: -8px;
    margin-top: -1px;
  }

  &__session-column-session-wrapper {
    border-right: 1px solid light.$ge-divider-default;
    flex: 1;
  }

  &__header-buttons {
    display: flex;
    align-items: center;
    position: relative;
    color: light.$on-background-primary-default;
  }

  &__loading-wrapper {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    margin: 0 auto;
  }

  &__loading-blank-space {
    height: 28px;
    background-color: light.$surface-primary-default;
  }
}
